import React, { createContext } from 'react';
import ErrorPage from '../components/common/error-page';
export const HubContext = createContext(null);
const HubContextProvider = ({
  children,
  hubInfo
}) => {
  if (!(hubInfo === null || hubInfo === void 0 ? void 0 : hubInfo.hubId)) return React.createElement(ErrorPage, null);
  return React.createElement(HubContext.Provider, {
    value: {
      hubInfo
    }
  }, children);
};
export default HubContextProvider;