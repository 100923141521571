import { L10nModule } from 'bernie-l10n';
export const localization = locale => {
  const mod = new L10nModule();
  try {
    mod.current = locale;
    mod.globalize = require(`documentation-pwa-localization/dist/bundle-${locale}`);
  } catch (_a) {
    mod.current = 'en_US';
    mod.globalize = require(`documentation-pwa-localization/dist/bundle-en_US`);
  }
  return mod;
};