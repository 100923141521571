import { getConfig } from 'bernie-config';
import { SystemEventLevel } from '@shared-ui/logger-context';
export const documentQuery = `query ($hubId: String!, $context: ContextInput!, $documentId: String!) {
  documentation(hubId: $hubId, context: $context) {
      document(id: $documentId) {
        ... on DocumentationDocumentSuccessResponse {
            location
            title
            intro
        }
        ... on DocumentationDocumentErrorResponse {
            messageText {
                text
            }
        }
    }
}
}`;
export const getDocumentQueryBody = (context, hubId, documentId) => {
  const {
    site,
    locale,
    deviceInformation
  } = context;
  return JSON.stringify({
    query: documentQuery,
    variables: {
      context: {
        siteId: site.id,
        locale,
        device: {
          type: (deviceInformation === null || deviceInformation === void 0 ? void 0 : deviceInformation.type) || 'DESKTOP'
        }
      },
      hubId,
      documentId
    }
  });
};
export const getDocumentInfo = async (options, hubId, documentId) => {
  var _a, _b, _c;
  const documentQueryBody = getDocumentQueryBody(options.context, hubId, documentId);
  const {
    variables
  } = getConfig();
  try {
    const documentQueryResponse = await fetch(variables.GRAPHQL_ENDPOINT, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        'Device-User-Agent-ID': options.context.deviceId,
        'trace-id': options.context.traceInfo['Trace-ID']
      },
      body: documentQueryBody
    });
    const {
      data
    } = await documentQueryResponse.json();
    return {
      intro: (_a = data.documentation.document) === null || _a === void 0 ? void 0 : _a.intro,
      title: (_b = data.documentation.document) === null || _b === void 0 ? void 0 : _b.title,
      location: data.documentation.document.location || documentId
    };
  } catch (e) {
    (_c = options.request) === null || _c === void 0 ? void 0 : _c.log(['documentation-pwa.error.getDocumentLocation', options.context.traceInfo['Trace-ID']], {
      level: SystemEventLevel.WARN,
      error: e.messageText
    });
    return {
      location: documentId
    };
  }
};
export const redirectToNewDocumentLocation = (options, hubId, newLocation) => {
  options.response.redirect(301, `/${hubId}/${newLocation}`);
};