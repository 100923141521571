export const isInViewport = element => {
  if (typeof window === 'undefined') return false;
  const rect = element.getBoundingClientRect();
  // temp, 113 is the header height, TODO: fins a better way to solve for this
  return rect.top >= 113 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
};
export const toSnakeCase = kebabCase => {
  return kebabCase === null || kebabCase === void 0 ? void 0 : kebabCase.replace('-', '_');
};
export const getPathName = url => new URL(url).pathname;
export const PAGE_TITLE = `Expedia Group Developer Hub`;
export const removeHubNameFromPath = path => {
  return path.split('/').slice(2).join('/');
};
export const cleanUpDocumentId = pathname => {
  let documentId = pathname;
  const isStartsWithSlash = documentId[0] === '/';
  const isEndsWithSlash = documentId[documentId.length - 1] === '/';
  if (isStartsWithSlash) documentId = documentId.replace('/', '');
  if (isEndsWithSlash) documentId = documentId.substring(0, documentId.length - 1);
  return documentId;
};