import * as React from 'react';
import { getHubContextProvider } from './context/get-context-provider';
export default function hubContextPlugin() {
  return {
    name: 'bernie-plugin-hub-context',
    onBrowserHydrateRenderContext(pluginContext, next) {
      const {
        hydratedState,
        setAppData
      } = pluginContext;
      const {
        hubInfoContext
      } = hydratedState;
      setAppData({
        hubInfoContext
      });
      return next(pluginContext);
    },
    onBrowserWrapRootElement(pluginContext, next) {
      const {
        getAppData,
        setRootElement
      } = pluginContext;
      const appData = getAppData();
      const HubInfoContextProvider = getHubContextProvider(appData.hubInfoContext);
      setRootElement(RootElement => props => React.createElement(HubInfoContextProvider, null, React.createElement(RootElement, null, props.children)));
      return next(pluginContext);
    }
  };
}