import React from 'react';
import useLocale from '../../hooks/useLocale';
import { Message, MessageType } from './message';
export const GenericErrorMessage = () => {
  const {
    formatContent
  } = useLocale();
  return React.createElement(Message, {
    title: formatContent('generic.content.error.message.title', 'Something went wrong'),
    body: formatContent('generic.content.error.message.body', "We couldn't display this content, please check again later"),
    type: MessageType.ERROR
  });
};