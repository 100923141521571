export const loadBrandData = async brand => {
  const brandHash = brand => {
    if (!brand) {
      return '';
    }
    const code = Array.from(brand).map(c => c.charCodeAt(0)).join('');
    return "brand-" + code;
  };
  const brandMap = {
    "brand-1011039511297114116110101114": async () => await import( /* webpackChunkName: "brand-1011039511297114116110101114" */"./eg_partner"),
    "brand-10112011210110010597": async () => await import( /* webpackChunkName: "brand-10112011210110010597" */"./expedia")
  };
  const brandIndex = brandHash(brand);
  if (brandIndex in brandMap) {
    return await brandMap[brandIndex]();
  } else {
    return await brandMap['brand-1011039511297114116110101114']();
  }
};