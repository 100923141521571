import { useLocalization } from '@shared-ui/localization-context';
function useLocale() {
  const {
    formatText
  } = useLocalization();
  const formatContent = (key, fallback, ...args) => {
    return formatText(key, args) || fallback;
  };
  return {
    formatContent
  };
}
export default useLocale;