import React from 'react';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
export var MessageType;
(function (MessageType) {
  MessageType["ERROR"] = "error";
  MessageType["WARNING"] = "warning";
  MessageType["INFO"] = "info";
})(MessageType || (MessageType = {}));
export const Message = ({
  title,
  body,
  type
}) => {
  return React.createElement("div", {
    style: {
      margin: 'auto'
    }
  }, React.createElement(UitkLayoutFlex, {
    space: "half",
    direction: "column",
    alignItems: "center"
  }, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'one'
    }
  }, React.createElement(UitkIcon, {
    name: type,
    id: type,
    size: UitkIconSize.EXTRA_SMALL
  })), React.createElement(UitkText, {
    theme: "emphasis"
  }, " ", title), React.createElement(UitkText, {
    theme: "default",
    size: 200
  }, body)));
};