export class DefaultController {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetch(options) {
    return Promise.resolve({
      pageId: this.pageId
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchPageData(options) {
    return Promise.resolve({
      pageId: this.pageId
    });
  }
}